<script lang="ts">
	import { page } from '$app/stores'
	import ValidationMessage from './../form/ValidationMessage.svelte'

	//Props
	export let name: string
	export let rows = 5
	export let placeholder = ''
	export let value
</script>

<label class="block text-sm font-semibold">
	<slot />
	<textarea
		class="appeareance-none shadow outline-none"
		class:border-primary-500={$page.form?.[name]}
		on:input
		{name}
		{placeholder}
		{rows}>{value}</textarea
	>
</label>
<ValidationMessage {name} />

<style>
	label {
		margin-top: theme('spacing.0');
		color: theme('colors.neutral.500');
	}
	textarea {
		width: 100%;
		resize: vertical;
		font-weight: theme('fontWeight.normal');
		color: theme('colors.neutral.800');
		border-radius: theme('borderRadius.md');
		border-width: theme('borderWidth.2');
		border-color: theme('colors.neutral.300');
		background-color: white;
		padding: theme('spacing.2');
		font-size: theme('fontSize.base');
		transition: 300ms;
		margin: 0;
	}

	textarea:focus:not(:read-only) {
		border-color: theme('colors.neutral.500');
	}

	textarea:read-only {
		color: theme('colors.neutral.800');
		background-color: theme('colors.neutral.100');
	}
</style>
