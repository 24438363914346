<script lang="ts">
	import { page } from '$app/stores'
	import ValidationMessage from './../form/ValidationMessage.svelte'

	export let label: string
	export let name: string
	export let value
</script>

<label class="block text-sm font-semibold">
	{label}
	<input
		class="appeareance-none shadow outline-none"
		class:border-primary-500={$page.form?.[name]}
		{name}
		{value}
	/>
</label>

<ValidationMessage {name} />

<style>
	label {
		margin-top: theme('spacing.0');
		color: theme('colors.neutral.500');
	}
	input {
		width: 100%;
		resize: vertical;
		font-weight: theme('fontWeight.normal');
		color: theme('colors.neutral.800');
		border-radius: theme('borderRadius.md');
		border-width: theme('borderWidth.2');
		border-color: theme('colors.neutral.300');
		background-color: white;
		padding: theme('spacing.2');
		font-size: theme('fontSize.base');
		transition: 300ms;
		margin: 0;
	}
</style>
